<!-- Base de Cabecera del Mto -->

<template>
  <v-toolbar class="header_Mto" v-bind="Entorno">
    <!-- Botón para cerrar la ventana modal -->
    <v-btn v-if="btn_cerrarForm" icon @click="onEvent()">
      <v-icon v-bind="Entorno.btnCerrar">{{ Entorno.btnCerrar.icon }}</v-icon>
    </v-btn>

    <!-- Título -->
    <v-toolbar-title :style="{ color: 'white' }">
      {{ titulo }}
    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
export default {
  props: {
    titulo: { type: String, default: "" },
    Entorno: {
      type: Object,
      default: function() {
        return this.$cfg.base.M.header;
      }
    },
    btn_cerrarForm: { type: Boolean, default: true }
  },

  methods: {
    // emito evento para cerrar la ventana modal
    onEvent() {
      this.$emit("onEvent", { accion: "cerrar_form" });
    }
  }
};
</script>
